import restaurant from "./images/biserica.jpg.crdownload";
import img_card from "./images/n4.jpg";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Victor",
        mireasa: "Natalia",
        data: "27 August 2022",
        data_confirmare: "18 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "vitya.angheluta9@gmail.com", 
       tel: "+373 600 74 184",
       phone: "tel:+37360074184",
       viber: "viber://chat?number=%2B37360074184",
       whatsapp: "https://wa.me/+37360074184",
       messenger: "https://www.messenger.com/t/100010964493861",
       tel1: "+373xxx",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Biserica Vocea Adevărului",
            data: "27 august 2022, sâmbătă, ora 16:00",
            adress: "Strada Putnei 27, Chișinău",
            harta: "https://goo.gl/maps/n9RrnZegtApPLGyCA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10874.054507798537!2d28.8456452!3d47.049774!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa4cf8a7d6a684e8e!2z0JPQvtC70L7RgSDQmNGB0YLQuNC90Ys!5e0!3m2!1sro!2s!4v1657699808809!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "PĂRINȚII SPIRITUALI",
            nasii_nume: "Eugen & Lilia",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Acum dar rămân acestea trei: credința, speranța, dragostea, dar cea mai mare dintre ele este dragostea.\" – 1 Corinteni 13:13",
        }
    ],

}

export default data;